const outputItem = document.querySelector(".js_e-area-calc-output");
const rangeItems = [...document.querySelectorAll(".js_e-area-calc-range")];
function getValue(range) {
   return +range._app_.rangeItem.inputStart.value
}
rangeItems.forEach(element => {
   element.addEventListener("_app_.rangeItem.onChange", () => {
      const value1 = getValue(rangeItems[0]);
      const value2 = getValue(rangeItems[1]);

      outputItem.textContent = value1 * value2;
   })
});