//=======================================================================================================================================================================================================================================================
/**
 * * Создает функционал range
 * Поддержка валидации и объединения подсказок
 * Использует noUiSlider, wNumb
 */
//=======================================================================================================================================================================================================================================================
import noUiSlider from "nouislider";
import wNumb from "wNumb";
import {InputMove} from "./input-move";
//=======================================================================================================================================================================================================================================================
/**
 * Класс представляющий RangeItem
 * @class
 */
class RangeItem {
   /** Префикс для имени события */
   static #eventPrefix = "_app_.rangeItem.";
   /** Объект с полными названиями событий */
   static events = {
      onChange: this.#eventPrefix + "onChange",
   };
   /** Объект с экземплярами событий */
   static #events = {
      /** Вызывается после любого изменения в `RangeItem` */
      onChange: new Event(this.events.onChange, {bubbles: true, cancelable: true}),
   };
   static divide(number, divider) {
      return number + (divider - (number % divider)) % divider
   }
   /** Сбросить */
   reset() {
      this.noUiSlider.reset();
      this.inputStart.value = this.config.min;
   }
   /**
    * Создает Элемент `RangeItem`
    * @param {Element} el Элемент `RangeItem`
    * @property {Element} range Элемент `RangeItem Slider`
    * @property {Element} inputStart Элемент `RangeItem Input Start`
    * @property {object} config Настройки `RangeItem`
    * @property {number} config.min Минимальное значение `RangeItem`
    * @property {number} config.max Максимальное значение `RangeItem`
    * @property {number} config.step Шаг `RangeItem`
    * @property {noUiSlider} noUiSlider `noUiSlider`
    * @property {wNumb} wNumb `wNumb`
    */
   constructor(el) {
      el._app_ ??= {};
      el._app_.rangeItem = this;

      this.el = el;
      this.range = el.children[1];

      const inputBody = el.firstElementChild;
      this.inputStart = inputBody.firstElementChild;

      const limiters = el.lastElementChild;
      this.limiterStart = limiters.firstElementChild;
      this.limiterEnd = limiters.lastElementChild;

      const [min, max, step] = el.getAttribute("data-range").split(",").map(value => +value);
      this.config = {min, max, step: step || 1, };

      this.wNumb = wNumb;

      this.inputStart.value = this.config.min;
      this.limiterStart.textContent = this.config.min;
      this.limiterEnd.textContent = this.config.max;

      const moveId = this.inputStart.getAttribute("data-move");
      if (moveId) {
         InputMove.inputs[moveId].to.forEach(el => {
            el._app_.inputMove.setValue();
         });
      }

      this.noUiSlider = noUiSlider.create(this.range, {
         start: [this.config.min],
         connect: Boolean(1),
         margin: this.config.step,
         step: this.config.step,
         tooltips: Boolean(1),
         range: {
            "min": [this.config.min],
            "max": [this.config.max],
         },
         format: {
            to: (value) => {
               return wNumb({
                  decimals: 0,
               }).to(value);
            },
            from: (value) => value,
         }
      });
      this.noUiSlider.on("slide", () => {
         const sliderValuesUpdate = this.noUiSlider.get();

         this.inputStart.value = sliderValuesUpdate;
         const moveId = this.inputStart.getAttribute("data-move");
         if (moveId) {
            InputMove.inputs[moveId].to.forEach(el => {
               el._app_.inputMove.setValue();
            });
         }
         this.el.dispatchEvent(RangeItem.#events.onChange);
      });
   }
}
//=======================================================================================================================================================================================================================================================
const rangeBlocks = document.querySelectorAll(".js_e-rng");
rangeBlocks.forEach(block => new RangeItem(block));
//=======================================================================================================================================================================================================================================================
