let lastInput;
function checkInput(input) {
   if (!input) return;
   const span = input.parentElement.lastElementChild.lastElementChild
   const files = input.files;
   const parent = input.closest(".file");

   if (files.length > 1) span.textContent = `Файлы выбраны (${files[0].name} и еще ${files.length - 1})`;
   if (files.length === 1) span.textContent = `Файл выбран (${files[0].name})`;

   if (files[0]) parent.classList.add("file_uploaded");
   else parent.classList.remove("file_uploaded");
}
addEventListener("change", ({target}) => checkInput(target.closest(".file input[type='file']")));
addEventListener("blur", () => checkInput(lastInput))