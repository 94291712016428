const header = document?.querySelector('.header');

function calc() {
   document.body.style.setProperty('--header-height', `${header.offsetHeight}px`);
   document.body.style.setProperty('--vh', `${innerHeight * 0.01}px`);
}
addEventListener("resize", calc);
addEventListener("load", calc);
addEventListener("click", calc);
calc();
