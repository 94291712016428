import {validateForms} from '../functions/validate-forms';

validateForms(".step-form", [
   {
      ruleSelector: '.step-form .input_name',
      rules: [
         {
            rule: 'required',
            value: true,
            errorMessage: ' '
         }
      ]
   },
   {
      ruleSelector: '.step-form .input_tel',
      tel: true,
      telError: 'Введите корректный телефон',
      rules: [
         {
            rule: 'required',
            value: true,
            errorMessage: ' '
         }
      ]
   },
   {
      ruleSelector: '.step-form .input_mail',
      rules: [
         {
            rule: 'required',
            value: true,
            errorMessage: ' '
         },
         {
            rule: 'email',
            value: true,
            errorMessage: ' '
         }
      ]
   },
]);