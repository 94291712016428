function debounce(func, timeout = 300) {
   let timer;
   return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {func.apply(this, args);}, timeout);
   };
}
/**
 * Класс представляющий RegionInput
 * @class
 */
class RegionInput {
   /** Префикс для имени события */
   static #eventPrefix = "_app_.regionInput.";
   /** Объект с полными названиями событий */
   static events = {
      onChange: this.#eventPrefix + "onChange",
   };
   /** Объект с экземплярами событий */
   static #events = {
      /** Вызывается после любого изменения в `RegionInput` */
      onChange: new Event(this.events.onChange, {bubbles: true, cancelable: true}),
   };
   /** Инициировать */
   #init() {
      this.el.addEventListener("focus", () => {
         this.showList();
      });
      this.el.addEventListener("blur", () => {
         this.hideList();
      });
      this.el.addEventListener("keyup", debounce(async () => {
         this.showList();
         await this.search(this.el.value);
      }, 300));
      this.list.addEventListener("click", (e) => {
         const li = e.target.closest("li");
         if (!li) return;

         this.el.value = li.textContent;
         const [region, area, city] = li.dataset.address.split(",");
         this.region.value = region;
         this.area.value = area;
         this.city.value = city;
      })
   }
   async search(query) {
      if (!query) return this.#buildList();

      const response = await fetch("https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address", {
         method: "POST",
         mode: "cors",
         headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + this.api
         },
         body: JSON.stringify({query: query})
      });
      if (!response.ok) return;
      const json = await response.json();
      this.#buildList(json.suggestions);
   }
   #buildList(items = []) {
      let html = "";
      items.forEach((item, i) => {
         html += `<li data-address="${item.data.federal_district || ""},${item.data.region || ""},${item.data.city || item.data.settlement || item.data.area || ""}">${item.value}</li>`;
      });
      this.list.innerHTML = html;
   }
   showList() {
      this.list.classList.add("--show");
   }
   hideList() {
      this.list.classList.remove("--show");
   }
   /**
    * Создает Элемент `RegionInput`
    * @param {Element} el Элемент `RegionInput`
    * @property {Element} el Элемент `RegionInput`
    */
   constructor(el) {
      el._app_ ??= {};
      el._app_.regionInput = this;

      this.el = el;
      this.api = el.dataset.api;
      this.list = el.parentElement.querySelector(".js_e-region-list");
      this.region = el.parentElement.querySelector(".js_e-region-region");
      this.area = el.parentElement.querySelector(".js_e-region-area");
      this.city = el.parentElement.querySelector(".js_e-region-city");

      this.#init();
   }
}
const inputs = document.querySelectorAll(".js_e-region-input");
inputs.forEach(input => new RegionInput(input));