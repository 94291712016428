import JustValidate from "just-validate";
import Inputmask from "inputmask";
import GraphModal from "graph-modal";

export const validateForms = (selector, rules, afterSend) => {
  const form = document?.querySelector(selector);
  const telSelector = form?.querySelector('input[type="tel"]');

  if (!form) {
    // console.error('Нет такого селектора!');
    return false;
  }

  if (!rules) {
    form.addEventListener("submit", handleSubmitEvent);
    // console.error('Вы не передали правила валидации!');
    return false;
  }

  if (telSelector) {
    const inputMask = new Inputmask("(+7)|(8) (999) 999-99-99", {
      placeholder: "0",
    });
    inputMask.mask(telSelector);

    for (let item of rules) {
      if (item.tel) {
        item.rules.push({
          rule: "function",
          validator: function () {
            const phone = telSelector.inputmask.unmaskedvalue();
            return phone.length === 10;
          },
          errorMessage: " ",
        });
      }
    }
  }

  const validation = new JustValidate(selector);

  for (let item of rules) {
    if (form.querySelector(item.ruleSelector)) {
      validation.addField(item.ruleSelector, item.rules);
    }
  }

  validation.onSuccess(handleSubmitEvent);
};
async function handleSubmitEvent(ev) {
  ev.preventDefault();
  let formData = new FormData(ev.target);
  const action = ev.target.getAttribute("action");

  const response = await fetch(action, { method: "POST", body: formData }); // Отправка
  const data = await response.json();
  if (data?.status !== "error") handleSubmit();

  function handleSubmit() {
    ym(91675679, "reachGoal", "quiz");

    const targetModal = ev.target.getAttribute("data-modal-target");

    const pop = new GraphModal();
    if (targetModal) pop.open(targetModal);
  }

  ev.target.reset();
}
function getUtmValues() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmValues = {};
  const utmParamNames = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term",
  ];
  utmParamNames.forEach((paramName) => {
    const paramValue = urlParams.get(paramName);
    if (paramValue) {
      utmValues[paramName] = paramValue;
    }
  });
  return utmValues;
}

// Функция для замены {placeholder} в строке на соответствующие значения utm-параметров
function replacePlaceholdersWithUtmValues(string, utmValues) {
  Object.keys(utmValues).forEach((paramName) => {
    const placeholder = `{${paramName}}`;
    if (string.includes(placeholder)) {
      string = string.replace(placeholder, utmValues[paramName]);
    }
  });
  return string;
}

// Функция для добавления скрытых полей с utm-значениями в формы
function addUtmFieldsToForms() {
  const utmValues = getUtmValues();
  if (Object.keys(utmValues).length > 0) {
    const forms = document.querySelectorAll("form");
    forms.forEach((form) => {
      Object.keys(utmValues).forEach((paramName) => {
        const paramValue = replacePlaceholdersWithUtmValues(
          utmValues[paramName],
          utmValues
        );
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = "quiz[" + paramName + "]";
        hiddenInput.value = paramValue;
        form.appendChild(hiddenInput);
      });
    });
  }
}

// Вызов функции для добавления скрытых полей с utm-значениями в формы при загрузке страницы
addUtmFieldsToForms();