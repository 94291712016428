/**
 * Класс представляющий InputMove
 * @class
 */
export class InputMove {
   /** Флаг, указывающий, был ли инициирован */
   static #wasInit;
   /** Инициировать */
   static #init() {
      if (this.#wasInit) return;
      this.#wasInit = true;

      this.fromInputs = document.querySelectorAll("[data-move]");
      [...this.fromInputs].forEach(el => {
         const id = el.getAttribute("data-move");

         if (!InputMove.inputs[id]) {
            InputMove.inputs[id] = {};
            InputMove.inputs[id].from = [];
            InputMove.inputs[id].to = [];
         }

         InputMove.inputs[id].from.push(el);
      });

      addEventListener("change", e => {
         const target = e.target.closest("[data-move]");
         if (!target) return;
         const id = target.getAttribute("data-move");

         InputMove.inputs[id].to.forEach(el => {
            el._app_.inputMove.setValue(target);
         });
      });
   }
   static inputs = {};
   setValue(input) {
      const fromInputs = InputMove.inputs[this.id].from;
      const type = fromInputs[0].type;

      if (!input) return [...fromInputs].forEach(el => {
         if (type === "checkbox") {
            return this.el.value = el.checked ? el.value : "";
         } else if (type === "radio") {
            if (el.checked) return this.el.value = el.value;
         } if (type === "file") {
            if (el.files.length) return this.el.files = el.files;
         } else this.el.value = el.value;
      });
      if (type === "checkbox") return this.el.value = input.checked ? input.value : "";
      if (type === "file") return this.el.files = input.files;
      return this.el.value = input.value;
   }
   /**
    * Создает Элемент `InputMove`
    * @param {Element} el Элемент `InputMove`
    * @property {Element} el Элемент `InputMove`
    */
   constructor(el) {
      InputMove.#init();

      el._app_ ??= {};
      el._app_.inputMove = this;

      this.el = el;
      this.id = el.getAttribute("data-move-id");
      InputMove.inputs[this.id]?.to.push(el);
   }
}
//=======================================================================================================================================================================================================================================================
const inputMoveIdEls = document.querySelectorAll("[data-move-id]");
[...inputMoveIdEls].forEach(el => {
   new InputMove(el);
});
const keysInputMove = Object.keys(InputMove.inputs);
keysInputMove.forEach(key => {
   const obj = InputMove.inputs[key];
   obj.to.forEach(el => {
      el._app_.inputMove.setValue();
   });
});