let clickedElement;

addEventListener("click", (e) => {
   const target = e.target.closest("[data-graph-path='pop']");
   if (!target) return;

   clickedElement = target;
})
export function handleOpen({modalContainer: modal}) {
   if (!modal.matches("[data-graph-target='pop']")) return;

   const content = modal.querySelector(".graph-modal__content");
   const videoSrc = clickedElement.getAttribute("data-video");
   if (videoSrc) {
      content.innerHTML = `<video src="${videoSrc}" controls></video>`;
      content.firstElementChild.play();
   }

   const videoIframe = clickedElement.getAttribute("data-iframe");
   if (videoIframe) {
      content.innerHTML = `<iframe src="${videoIframe}" allowfullscreen></iframe>`;
   }
}
export function handleClose({modalContainer: modal}) {
   if (!modal.matches("[data-graph-target='pop']")) return;


   const content = modal.querySelector(".graph-modal__content");
   content.innerHTML = "";

   clickedElement = null;
}